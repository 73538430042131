<template>
  <svg
    :class="{ transparent: sideMenuVisible }"
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80.118 80.118"
  >
    <g id="Group_163" data-name="Group 163" transform="translate(-1411 -237)">
      <g id="Group_117" data-name="Group 117" transform="translate(1411 237)">
        <rect
          id="logo-background"
          data-name="Rectangle 48"
          width="80.118"
          height="80.118"
          fill="#0f0b33"
        />
        <g data-name="Group 116" transform="translate(31.554 63.353) rotate(-90)">
          <path
            class="kroot-logo-path"
            data-name="Path 42"
            d="M11.107,1.994,10.281.221A.382.382,0,0,0,9.743.052L.191,5.566A.382.382,0,0,0,0,
      5.9V7.258a.382.382,0,0,0,.191.331l9.662,5.578A.382.382,0,0,0,10.39,13l.825-1.774a.381.381,0,0,
      0-.156-.492L4.394,6.907a.382.382,0,0,1,0-.663l6.557-3.758A.382.382,0,0,0,11.107,1.994Z"
            transform="translate(0 1.999)"
            fill="#fff"
          />
          <path
            class="kroot-logo-path"
            data-name="Path 43"
            d="M11.107,1.994,10.28.221A.381.381,0,0,0,9.743.052L.191,5.566A.382.382,0,0,0,0,5.9V7.258a.382.382
  ,0,0,0,.191.331l9.662,5.578A.382.382,0,0,0,10.39,13l.825-1.774a.382.382,0,0,0-.156-.492L4.394,
      6.907a.382.382,0,0,1,0-.663l6.557-3.758A.382.382,0,0,0,11.107,1.994Z"
            transform="translate(35.842 1.999)"
            fill="#fff"
          />
          <path
            class="kroot-logo-path"
            data-name="Path 44"
            d="M.191,17.285l1.613.931a.382.382,0,0,0,.537-.17L10.465.543A.382.382,0,0,0,10.116,0L8.083.009A.
  382.382,0,0,0,7.739.23L.036,16.793A.381.381,0,0,0,.191,17.285Z"
            transform="translate(13.37 0)"
            fill="#fff"
          />
          <g data-name="Group 109" transform="translate(23.609 0.147)">
            <path
              class="kroot-logo-path"
              data-name="Path 45"
              d="M4.127.224,3.289,2.029a.384.384,0,0,0,.152.488A7.1,7.1,0,0,1,6.806,8.553a7.1,7.1,0,0,1-6.46,
  7.069.379.379,0,0,0-.31.537c.263.564.677,1.452.882,1.887a.383.383,0,0,0,.407.216,9.845,9.845,0,
  0,0,8.221-9.71A9.837,9.837,0,0,0,4.66.05.38.38,0,0,0,4.127.224Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'krootLogo',
  props: {
    sideMenuVisible: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  #logo-background {
    transition: 0.5s fill ease-in-out;
  }
}

svg.transparent {
  #logo-background {
    fill: transparent;
    transition: 0.5s fill ease-in-out;
  }
}
</style>
