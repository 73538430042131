import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import PageNotFound from './views/PageNotFound.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound,
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/diensten',
      name: 'Diensten',
      component: () => import(/* webpackChunkName: "diensten" */ './views/Diensten.vue'),
    },
    {
      path: '/sitemap',
      name: 'Sitemap',
      component: () => import(/* webpackChunkName: "sitemap" */ './views/Sitemap.vue'),
    }, {
      path: '/contact',
      name: 'Contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
    },
  ],
});

router.afterEach((to) => {
  // Offset for scrolling. Navbar has 80px in height
  const offset = 80;

  // Timeout is needed for elements to render / mount before trying to access them
  if (to && to.hash) {
    setTimeout(() => {
      const targetElement = document.getElementById(to.hash.slice(1));

      if (!targetElement) {
        return;
      }

      const targetOffset = targetElement.offsetTop;
      document.getElementById('content-wrapper').scrollTop = targetOffset - offset;
    }, 500);
  } else {
    document.getElementById('content-wrapper').scrollTop = 0;
  }
});

export default router;
