<template>
  <div class="client-showcase-row page-row">
    <div class="container">
      <div class="col col--4-12">
        <div class="logo-image-wrapper">
          <img class="logo-image" src="/img/logo-hiretime.png" alt="Logo Hiretime" />
        </div>
      </div>
      <div class="col col--4-12">
        <div class="logo-image-wrapper">
          <img class="logo-image" src="/img/logo-vakmedianet.png" alt="Logo Vakmedianet" />
        </div>
      </div>
      <div class="col col--4-12">
        <div class="logo-image-wrapper">
          <img class="logo-image" src="/img/logo-carriere.svg" alt="Logo SBS6" />
        </div>
      </div>
      <div class="col col--4-12 shift">
        <div class="logo-image-wrapper">
          <img class="logo-image" src="/img/logo-defigners.svg" alt="Logo Defigners" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientShowcaseRow',
};
</script>

<style lang="scss" scoped>
.client-showcase-row {
  .container {
    max-width: $screen-lg-max;
    margin-bottom: -80px;

    .logo-image-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 80px;
    }

    .shift {
      @include grid-shift(4);
    }

    .logo-image {
      filter: grayscale(100%);
      opacity: 0.5;
      height: 40px;
    }
  }

  @include sm {
    .container {
      .col--4-12 {
        @include grid-column(6);
      }

      .logo-image {
        height: 25px;
      }

      .shift {
        @include grid-shift(0);
      }
    }
  }

  @include xsm {
    .container {
      margin-bottom: -60px;

      .col--4-12 {
        @include grid-column(12);
      }

      .logo-image-wrapper {
        margin-bottom: 60px;
      }

      .logo-image {
        height: 25px;
      }

      .shift {
        @include grid-shift(0);
      }
    }
  }
}
</style>
