<template>
  <nav class="navbar" :class="{ 'on-top': scrolledToTop && !sideMenuVisible }">
    <div class="container-nav">
      <div class="col">
        <Logo
          id="logo"
          :hide-text="!scrolledToTop && !sideMenuVisible"
          :sideMenuVisible="sideMenuVisible"
        />
        <div id="navbar-links" :class="{ 'hidden-by-scroll': !scrolledToTop }">
          <router-link class="navbar-link" :to="{name: 'Diensten'}" aria-label="ga naar Diensten">Diensten</router-link>
          <router-link class="navbar-link" :to="{name: 'Contact'}" aria-label="ga naar Contact">Contact</router-link>
          <BaseButton
            ghost
            link="mailto:company@kroot.dev"
            small
            aria-label="Mail ons"
          >&lt;Mailto:/&gt;</BaseButton>
        </div>
        <Hamburger
          class="hamburger"
          :class="{ 'hidden-by-scroll': scrolledToTop }"
          @click.native="$emit('hamburger-clicked')"
          :sideMenuVisible="sideMenuVisible"
        />
      </div>
    </div>
  </nav>
</template>


<script>
import Logo from '@/components/Logo.vue';
import BaseButton from './BaseButton.vue';
import Hamburger from '@/assets/images/hamburger.svg.vue';

export default {
  name: 'Navbar',
  props: ['sideMenuVisible', 'scrolledToTop'],
  components: {
    Logo,
    BaseButton,
    Hamburger,
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  height: 80px;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  transition: 0.3s all ease-in-out;
  pointer-events: none;

  #logo, .hamburger {
    pointer-events: all;
  }

  &.on-top {
    background-color: $white;
    pointer-events: all;
  }

  .col {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.hamburger {
  transition: 0.3s all ease-in-out;

  &.hidden-by-scroll {
    opacity: 0;
    pointer-events: none;
  }

  @include md {
    &.hidden-by-scroll {
      opacity: 1;
      pointer-events: all;
    }
  }
}

#navbar-links {
  height: 80px;
  display: flex;
  align-items: center;
  padding-right: 100px;
  transition: 0.3s all ease-in-out;
  position: absolute;
  right: 0;

  .navbar-link {
    text-align: left;
    font-size: 16px;
    font-family: "PT Mono", monospace;
    letter-spacing: 0;
    color: $black;
    opacity: 1;
    margin-left: 50px;
    text-decoration: none;
    margin: 0 25px;
  }

  &.hidden-by-scroll {
    opacity: 0;
    pointer-events: none;
  }

  @include md {
    display: none !important;
  }
}
</style>
