<template>
  <div class="base-button">
    <button
      v-if="!link && !routerLink"
      v-bind="$attrs"
      :class="{ primary, gradient, white, small, ghost, 'inverse-ghost': inverseGhost, 'ghost-red': ghostRed}"
      class="core-button"
      v-on="$listeners"
    >
      <slot />
    </button>
    <a
      v-if="link"
      v-bind="$attrs"
      :href="link"
      :class="{ primary, gradient, white, small, ghost, 'inverse-ghost': inverseGhost , 'ghost-red': ghostRed }"
      class="core-button"
      v-on="$listeners"
    >
      <slot />
    </a>
    <router-link
      v-if="routerLink"
      v-bind="$attrs"
      :class="{ primary, gradient, white, small, ghost, 'inverse-ghost': inverseGhost , 'ghost-red': ghostRed }"
      class="core-button"
      v-on="$listeners"
    >
      <slot />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    ghostRed: {
      type: Boolean,
      default: false,
    },
    inverseGhost: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    routerLink: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  .core-button {
    @extend .mono-small;

    background: none;
    border: 0;
    height: 50px;
    min-width: 180px;
    padding: 0 20px;
    line-height: 50px;
    background-color: red;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;

    &.primary {
      background-color: $purpleLight;
      color: $white;
      box-shadow: 0 4px 8px rgba($purpleLight, 0.2);
    }

    &.gradient {
      background: $RedToDarkBlue;
      color: $white;
      box-shadow: 0 4px 8px rgba($purpleLight, 0.2);
    }

    &.white {
      background-color: $white;
      color: $purpleMedium;
      box-shadow: 0 4px 8px rgba($purpleLight, 0.2);
    }

    &.ghost {
      background-color: transparent;
      border: solid 1px $black;
      color: $black;
      line-height: 50px;
      font-size: 16px;
      font-family: "PT Mono", monospace;
    }

    &.ghost-red {
      color: $red;
      border-color: $red;
    }

    &.inverse-ghost {
      background-color: transparent;
      border: solid 1px $greyMedium;
      color: $greyMedium;
      line-height: 50px;
      font-size: 16px;
      font-family: "PT Mono", monospace;
    }

    &.small {
      height: 40px;
      line-height: 38px;
    }
  }
}
</style>
