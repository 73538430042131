<template>
  <section class="our-services-row page-row page-row--default">
    <div class="container container--inherit-height">
      <div class="col col--12-12">
        <div class="col__layer content-text content-text--light">
          <div class="left">
            <div class="dotscontainer">
              <CanvasDots bg-colour="transparent" circle-colour="white" color-type="light"/>
            </div>
            <div class="content-image">
              <BaseImage src="Websites_v2.png" class="laptop-image" alt="Laptop" :max="2"></BaseImage>
              <BaseImage src="Ipad_v2.png" class="ipad-image" alt="iPad" :max="2"></BaseImage>
              <BaseImage src="afbeeldingen_kroot_v2.png" class="iphone-image" alt="iPhone" :max="2"></BaseImage>
              <div class="gradient" />
            </div>
          </div>
          <div class="right">
            <div class="content">
              <h2 class="h2">Specialisten in software.</h2>
              <div class="service-links text">
                <router-link class="service-link mono-medium" :to="{name: 'Diensten', hash: 'webapps'}">Web Apps</router-link> |
                <router-link class="service-link mono-medium" :to="{name: 'Diensten', hash: 'wordpress'}">Wordpress</router-link> |
                <router-link class="service-link mono-medium" :to="{name: 'Diensten', hash: 'backend'}">Backend</router-link> |
                <router-link class="service-link mono-medium" :to="{name: 'Diensten', hash: 'mobile-apps'}">Native Apps</router-link>
              </div>
              <p class="text">
                Software van hoge kwaliteit? Dat zijn webapplicaties, mobiele apps en API’s die perfect aansluiten op de wensen en behoeften van de organisatie en eindgebruikers. Door hoge kwaliteitsstandaarden te combineren met een constant groeiende expertise in alles digitaal, willen wij onszelf blijven pushen om zo goed mogelijke producten te leveren.
              </p>
              <BaseButton ghost router-link to="Diensten">Lees verder</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BaseButton, BaseImage, CanvasDots } from '@/components/global/';

export default {
  name: 'OurServicesRow',
  components: {
    BaseButton,
    BaseImage,
    CanvasDots,
  },
};
</script>

<style lang="scss" scoped>
.our-services-row {
  position: relative;

  .col__layer {
    background-color: $darkBlue;
    position: relative;

    .left,
    .right {
      height: inherit;
      width: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .left {
      height: inherit;
      overflow: hidden;
      position: relative;

      .dotscontainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .content-image {
        position: relative;
        right: 100px;
        height: 100%;
        width: 100%;
        .iphone-image {
          position: absolute;
          bottom: 5px;
          right: -290px;
          height: 815px;
        }
        .laptop-image {
          position: absolute;
          bottom: -850px;
          right: -150px;
        }
        .ipad-image {
          position: absolute;
          bottom: 120px;
          right: 0px;
        }
        .gradient {
          position: absolute;
          right: -100px;
          width: 300px;
          height: 800px;
          background: linear-gradient(to right, transparent, #25176d);
        }
      }
    }

    .right {
      right: 0;

      .content {
        height: inherit;
        left: 0;
        padding-left: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 40px;
        max-width: 700px;
      }

      .service-links {
        color: $red;
        margin-bottom: 40px;
        .service-link {
          text-decoration: underline;
          color: $red;
          padding-right: 5px;
          &:not(:first-of-type){
            padding-left: 5px;
          }
        }
      }

      /deep/ .core-button {
        color: $red;
        border-color: $red;
      }
    }
  }

  @include lg {
    .col__layer .right {
      .dots-wrapper {
        width: 20%;
      }

      .content {
        padding-left: 80px;
        margin-right: 40px;
      }
    }
  }

  @include md {
    .col__layer {
      .left,
      .right {
        position: initial;
        top: initial;
        left: initial;
        width: 100%;
      }

      .right {
        padding: 40px 0;
        position: relative;
      }

      .left {
        height: 400px;

        .content-image {
          .iphone-image {
            right: initial;
            left: 110px;
            bottom: -100px;
          }

          .laptop-image {
            right: initial;
            left: -1000px;
            bottom: -950px;
          }
          .ipad-image {
            right: initial;
            left: -450px;
            bottom: 20px;
          }
          .gradient {
            display: none;
          }
        }

        .dotscontainer {
          height:400px;
        }
      }
    }

    .col__layer .right {
      .content {
        margin-right: 80px;
      }
    }
  }

  @include sm {
    .col__layer {
      .right {
        padding: 40px 0;

        .content {
          padding-left: 40px;
          margin-right: 40px;
        }
      }
    }
  }
}
</style>
