<template>
  <section class="about-us-row page-row page-row--default">
    <div class="container container--inherit-height">
      <div class="col col--12-12">
        <div class="col__layer content-text content-text--light">
          <div class="right"></div>
          <div class="left">
            <div class="content">
              <p class="mono-small">&lt;Ons team/&gt;</p>
              <h2 class="h2">Wie we zijn.</h2>
              <p class="text">
                Kroot is een jong en ambitieus team van software developers en programmeurs. Vanuit Utrecht, in het historische Oudenoord 330, werken wij nauw samen met onze opdrachtgevers om digitale producten van hoge kwaliteit te leveren. Door oplossingsgericht te denken, duidelijk te communiceren en grondig te werk te gaan helpen wij jouw merk vooruit.
              </p>
            </div>

            <div class="dots-wrapper">
              <CanvasDots class="dots" bg-colour="transparent" circle-colour="white" color-type="light"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CanvasDots } from '@/components/global';

export default {
  name: 'AboutUsRow',
  components: {
    CanvasDots,
  },
};
</script>

<style lang="scss" scoped>
.about-us-row {
  position: relative;

  .col__layer {
    background-color: $black;
    position: relative;

    .left,
    .right {
      height: inherit;
      width: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .left {
      height: inherit;
      .content {
        height: inherit;
        left: 0;
        padding-left: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: calc(30% + 40px);
      }

      .dots-wrapper {
        position: absolute;
        width: 30%;
        height: inherit;
        top: 0;
        right: 0;

        .dots {
          height: inherit;
        }
      }
    }

    .right {
      right: 0;
      background: url(/img/Team.jpg);
      background-size: cover;
      background-position: center center;
    }
  }

  @include lg {
    .col__layer .left {
      .dots-wrapper {
        width: 20%;
      }

      .content {
        padding-left: 80px;
        margin-right: calc(20% + 40px);
      }
    }
  }

  @include md {
    .col__layer {
      .left,
      .right {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
      }

      .left {
        padding: 40px 0;
      }

      .right {
        height: 400px;
      }
    }

    .col__layer .left {
      .dots-wrapper {
        display: none;
      }

      .content {
        margin-right: 80px;
      }
    }
  }

  @include sm {
    .col__layer {
      .left {
        padding: 40px 0;

        .content {
          padding-left: 40px;
          margin-right: 40px;
        }
      }
    }
  }
}
</style>
