<template>
  <section class="projects-preview-row page-row">
    <div class="container">
      <div class="header">
        <div class="col col-header">
          <p class="mono-small">&lt;Cases/&gt;</p>
          <h2 class="h2">Een paar opdrachten waar we trots op zijn</h2>
        </div>
      </div>
      <div class="row">
        <div class="col col--12-12">
          <div
            class="panel panel--small-padding col__layer"
            style="background-image: url(https://picsum.photos/1920)"
          >
            <div class="content-text content-text--dark">
              <p class="mono-small">&lt;Client: <strong>Hiretime inc.</strong>&gt;</p>
              <h2 class="h1">Dit is een titel</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col--6-12">
          <div
            class="panel panel--small-padding col__layer content-text"
            style="background-image: url(https://picsum.photos/960)"
          >
            <div class="content-text content-text--light">
              <p class="mono-small">&lt;Client: <strong>Defigners V.O.F</strong>&gt;</p>
              <h2 class="h1">Dit is een titel</h2>
            </div>
          </div>
        </div>
        <div class="col col--6-12">
          <div
            class="panel panel--small-padding col__layer content-text"
            style="background-image: url(https://picsum.photos/960?2)"
          >
            <div class="content-text content-text--light">
              <p class="mono-small">&lt;Client: <strong>Hiretime inc.</strong>&gt;</p>
              <h2 class="h1">Dit is een titel</h2>
              <p class="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Illo quae soluta molestias veniam facilis iusto
                deserunt assumenda quisquam amet!
                Qui, soluta debitis. Culpa pariatur alias ipsum!
                Quaerat unde perspiciatis ex!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProjectsPreviewRow',
  props: {},
};
</script>

<style lang="scss" scoped>
.projects-preview-row {
  .header {
    @include grid-container;
    display: block;
    padding: 0 80px;
    margin: 100px 0;

    .mono-small {
      color: $greyLight;
    }

    .col-header {
      @include grid-column(6 of 12);
    }

    @include lg {
      .col-header {
        @include grid-column(9 of 12);
      }

      margin: 80px 0;
    }

    @include md {
      .col-header {
        @include grid-column(12 of 12);
      }

      padding: 0;
      margin: 40px 0;
    }
  }

  .row {
    display: block;
    height: 584px;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }

    .col {
      height: inherit;
    }

    @include lg {
      height: 388px;
    }

    @include md {
      &:not(:last-of-type) {
        margin-bottom: 0;
      }

      .col.col--6-12 {
        @include grid-column(12);
      }
    }

    @include xsm {
      height: 200px;
    }
  }
}
</style>
