<template>
  <header class="hero-row page-row">
    <CanvasDots class="dots"></CanvasDots>
    <div class="container container--inherit-height">
      <div class="col col--8-12">
        <div class="col__layer content-text">
          <p class="mono-big">
            Welkom bij
            <krootLogoNoBackground black />
            Kroot.
          </p>
          <h1 class="h1">Digitaal vakmanschap.</h1>
          <BaseButton
            gradient
            router-link
            :to="{name: 'Contact'}"
            aria-label="Neem contact met ons op"
            >Zoek niet verder</BaseButton>
        </div>
      </div>
      <div class="col col--4-12">
        <div class="col__layer content-image-col">
          <div class="content-image">
            <BaseImage src="iphone.png" class="iphone-image" alt="iPhone"></BaseImage>
            <BaseImage src="claw.png" :max="2" class="claw-image" alt="Claw"></BaseImage>
            <MaskEffectImage
              class="wrench-image"
              image-below-src="./img/wrench-below.png"
              image-above-src="./img/wrench-above.png"
              image-fallback-src="./img/wrench-fallback.png"
            ></MaskEffectImage>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import krootLogoNoBackground from '@/assets/images/krootLogoNoBackground.svg.vue';
import {
  BaseButton, BaseImage, MaskEffectImage, CanvasDots,
} from '@/components/global';

export default {
  name: 'HeroRow',
  components: {
    BaseButton,
    BaseImage,
    MaskEffectImage,
    krootLogoNoBackground,
    CanvasDots,
  },
  props: {},
};
</script>

<style lang="scss" scoped>
$hero-row-height: 900px;

.hero-row {
  position: relative;
  height: $hero-row-height;

  .dots {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: calc(100% - 50px);
    z-index: -10;

    /deep/ .solid-dots {
      background-position:  2px 12px;
    }
  }

  .h1,
  .mono-big {
    color: $black;
  }

  .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 180px;

    .kroot-logo-svg {
      transform: translateY(17px) rotate(90deg);
      margin: 0 12px;
    }

    .h1 {
      margin-left: -4px;
    }
  }

  .content-image-col {
    position: relative;

    .content-image {
      position: absolute;
      left: -340px;
      bottom: 270px;
      transform: rotate(30deg);

      .iphone-image {
        position: absolute;
        height: 678px;
        bottom: 0;
        z-index: 10;
      }
      .claw-image {
        position: absolute;
        height: 1206px;
        bottom: 150px;
        left: -200px;
        z-index: 8;
      }

      .wrench-image {
        position: absolute;
        width: 237px;
        bottom: 597px;
        left: 320px;
        z-index: 12;
      }
    }
  }

  .container {
    position: relative;
  }


  @include lg {
    height: ($hero-row-height * $screen-xlg-max) / $screen-xlg-max;
    .content-text {
      padding-left: 80px;
    }
  }

  @include md {
    height: ($hero-row-height * $screen-lg-max) / $screen-xlg-max;
    padding-top: 80px;

    .content-image-col .content-image {
      bottom: 240px;
    }

    .content-text {
      padding-left: 20px;
    }
  }

  @include sm {
    height: ($hero-row-height * $screen-md-max) / $screen-xlg-max;

    .col--8-12 {
      @include grid-column(12);
    }

    .col--4-12 {
      display: none;
    }

    .content-text {
      .h1 {
        margin-left: -2px;
      }
    }
  }
}
</style>
