<template>
  <section class="page-not-found container">
    <div class="not-found-wrapper">
      <p class="mono-small">&lt;Error: 404 /&gt;</p>
      <h1 class="h1">Pagina niet gevonden.</h1>
      <router-link
        class="link"
        to="/"
      >Terug naar home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {},
};
</script>

<style lang="scss" scoped>
.page-not-found {
  &.container {
    margin-top: 80px * 4;

    .not-found-wrapper {
      text-align: center;

      .link {
        line-height: 40px;
      }
    }
  }
}
</style>
