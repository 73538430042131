<template>
  <div class="mask-effect-image" :class="effect">
    <img v-lazy="imageBelowSrc" class="image below one" alt="image-below" />
    <img v-lazy="imageBelowSrc" class="image below two" alt="image-below" />
    <img v-lazy="imageAboveSrc" class="image above" alt="image-above" />
    <img v-lazy="imageFallbackSrc" class="image fallback" alt="fallback image" />
  </div>
</template>

<script>
export default {
  name: 'MaskEffectImage',
  props: {
    imageBelowSrc: {
      type: String,
      defualt: null,
    },
    imageAboveSrc: {
      type: String,
      default: null,
    },
    imageFallbackSrc: {
      type: String,
      default: null,
    },
    effect: {
      type: String,
      validator(val) {
        return ['lines', 'circles', 'triangles', 'clockwork', 'hover'].includes(
          val,
        );
      },
      default: 'lines',
    },
  },
  methods: {
    // gets called to see what is currently on-screen
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
    },
  },
  data() {
    return {
      isVisible: true, // property currently used to imprement lazy loading
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin slantedBarClipPath($baseBottomRightY, $height, $slantDifference) {
  clip-path: polygon(
    0 $baseBottomRightY,
    0 ($baseBottomRightY - $height),
    100% ($baseBottomRightY - $height - $slantDifference),
    100% ($baseBottomRightY - $slantDifference)
  );
}

@keyframes barOne {
  0% {
    @include slantedBarClipPath(65%, 10%, 20%);
  }

  25% {
    @include slantedBarClipPath(10%, 10%, 20%);
  }

  75% {
    @include slantedBarClipPath(90%, 10%, 20%);
  }

  100% {
    @include slantedBarClipPath(65%, 10%, 20%);
  }
}

@keyframes barTwo {
  0% {
    @include slantedBarClipPath(120%, 40%, 20%);
  }

  75% {
    @include slantedBarClipPath(10%, 40%, 20%);
  }

  100% {
    @include slantedBarClipPath(120%, 40%, 20%);
  }
}

@keyframes circleOne {
  0% {
    clip-path: circle(150px at 85% 100%);
  }

  18% {
    clip-path: circle(250px at 85% 100%);
  }

  40% {
    clip-path: circle(180px at 85% 100%);
  }

  58% {
    clip-path: circle(400px at 85% 100%);
  }

  66% {
    clip-path: circle(350px at 85% 100%);
  }

  74% {
    clip-path: circle(430px at 85% 100%);
  }

  100% {
    clip-path: circle(150px at 85% 100%);
  }
}

@keyframes circleTwo {
  0% {
    clip-path: circle(150px at 15% 0);
  }

  18% {
    clip-path: circle(200px at 15% 0);
  }

  40% {
    clip-path: circle(167px at 15% 0);
  }

  58% {
    clip-path: circle(300px at 15% 0);
  }

  66% {
    clip-path: circle(250px at 15% 0);
  }

  74% {
    clip-path: circle(220px at 15% 0);
  }

  100% {
    clip-path: circle(150px at 15% 0);
  }
}

@keyframes clockOne {
  0%,100%{
    clip-path: polygon(50% 50%, -100% 200%, 200% 200%);
  }
  25%{
    clip-path: polygon(50% 50%, -100% -100%, -100% 200%);
  }
  50%{
    clip-path: polygon(50% 50%, 200% -100%, -100% -100%);
  }
  75%{
    clip-path: polygon(50% 50%, 200% 200%, 200% -100%);
  }
}

@keyframes clockTwo {
  0%,100% {
    clip-path: polygon(50% 50%, 50% 125%, 125% 125%);
  }
  10% {
    clip-path: polygon(50% 50%, -25% 125%, 50% 125%);
  }
  20% {
    clip-path: polygon(50% 50%, -25% 66%, -25% 125%);
  }
  30% {
    clip-path: polygon(50% 50%, -25% 33%, -25% 66%);
  }
  40% {
    clip-path: polygon(50% 50%, -25% -25%, -25% 33%);
  }
  50% {
    clip-path: polygon(50% 50%, 50% -25%, -25% -25%);
  }
  60% {
    clip-path: polygon(50% 50%, 125% -25%, 50% -25%);
  }
  70% {
    clip-path: polygon(50% 50%, 125% 33%, 125% -25%);
  }
  80%{
    clip-path: polygon(50% 50%, 125% 66%, 125% 33%);
  }
  90% {
    clip-path: polygon(50% 50%, 125% 125%, 125% 66%);
  }
}

@mixin traingle1Transform($size,$locationX,$locationY){
    clip-path: polygon(
      50%*$size+$locationX 0% *$size+$locationY,
      0%*$size+$locationX 90%*$size+$locationY ,
      100%*$size+$locationX 90%*$size+$locationY );
}

@mixin triangle2Transform($size, $locationX, $locationY){
    clip-path: polygon(
      50%*$size+$locationX 0%*$size+$locationY,
      0%*$size+$locationX 100%*$size+$locationY,
      15%*$size+$locationX 100%*$size+$locationY,
      50%*$size+$locationX 25%*$size+$locationY,
      75%*$size+$locationX 85%*$size+$locationY,
      10%*$size+$locationX 85%*$size+$locationY,
      10%*$size+$locationX 100%*$size+$locationY,
      100%*$size+$locationX 100%*$size+$locationY);
}

@keyframes triangleOne {
  0%,100% {
    @include traingle1Transform(0.25, 80,80 );
  }
  50% {
    @include traingle1Transform(1,0 ,0 );
  }
}

@keyframes triangleTwo {
  0%, 100% {
    @include triangle2Transform(1,0,0);
  }
  50% {
    @include triangle2Transform(0.25,-15 ,-15);
  }
}

.mask-effect-image {
  .image {
    transition: clip-path 0.9s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;

    &.below {
      z-index: 20;
    }

    &.above {
      z-index: 10;
    }

    &.fallback {
      visibility: hidden;
    }

    &.pause-animation {
      animation-play-state: paused;
    }
  }
}

.mask-effect-image.lines {
  .image {
    &.one {
      @include slantedBarClipPath(65%, 10%, 20%);
      animation-name: barOne;
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }

    &.two {
      @include slantedBarClipPath(120%, 40%, 20%);
      animation-name: barTwo;
      animation-duration: 13s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }
  }
}

.mask-effect-image.circles {
  .image {
    &.one {
      animation-name: circleOne;
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }

    &.two {
      animation-name: circleTwo;
      animation-duration: 13s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }
  }
}

.mask-effect-image.hover {
  .image {
    &.one {
      display: none;
    }
    &.two {
      position: relative;
      clip-path: polygon(100% 100%, 30% 100%, 100% 0%, 100% 0%);
      &:hover {
        clip-path: polygon(100% 100%, 0% 100%, 90% 0%, 100% 0%);
      }
    }
  }
}

.mask-effect-image.clockwork {
  .image {
    &.one {
      animation-name: clockOne;
      animation-duration: 23s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: linear;
    }
    &.two {
      animation-name: clockTwo;
      animation-duration: 13s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: linear;
    }
  }
}

.mask-effect-image.triangles {
  .image {
    &.one {
      animation-name: triangleOne;
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }
    &.two {
      animation-name: triangleTwo;
      animation-duration: 13s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }
  }
}


//CSS hack for IE10-11 and Microsoft Edge
//source: https://browserstrangeness.github.io/css_hacks.html
_:-ms-lang(x),
.mask-effect-image .fallback {
  visibility: visible !important;
}
_:-ms-lang(x),
.mask-effect-image .above {
  visibility: hidden;
}
_:-ms-lang(x),
.mask-effect-image .below {
  visibility: hidden;
}
</style>
