<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    @click="hamburgerClicked"
    :class="{ cross: sideMenuVisible }"
  >
    <g id="Group_255" data-name="Group 255" transform="translate(-1840 -222)">
      <g id="Group_237" data-name="Group 237" transform="translate(0 -38)">
        <rect
          id="button-background"
          data-name="Rectangle 103"
          width="80"
          height="80"
          transform="translate(1840 260)"
          fill="#0f0b33"
        />
        <g id="Group_236" data-name="Group 236" transform="translate(608.126 -2105.843)">
          <line
            id="line-top"
            data-name="Line 25"
            x2="36.982"
            transform="translate(1253.874 2393.843)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="3"
          />
          <line
            id="line-middle"
            data-name="Line 23"
            x2="37"
            transform="translate(1253.874 2405.764)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="3"
          />
          <line
            id="line-bottom"
            data-name="Line 24"
            x2="22.182"
            transform="translate(1253.874 2417.685)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="3"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    sideMenuVisible: {
      type: Boolean,
    },
  },
  methods: {
    hamburgerClicked() {
      this.active = !this.active;
      this.$emit('toggle', { active: this.active });
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;

  #line-top,
  #line-middle,
  #line-bottom {
    transition: 0.5s transform ease-in-out, 0.5s opacity ease-in-out;
  }

  #line-top {
    transform: translate(1253.874px, 2393.843px) rotate(0deg);
  }

  #line-middle {
    transform: translate(1253.874px, 2405.764px) rotate(0deg);
  }

  #line-bottom {
    transform: translate(1253.874px, 2417.685px) rotate(0deg);
  }

  #button-background {
    transition: 0.5s fill ease-in-out;
  }

  &.cross {
    #line-top {
      transform: translate(1253.874px + 5px, 2417.685px + 1px) rotate(-45deg);
    }
    #line-middle {
      transform: translate(1253.874px + 5px, 2391.5px + 1px) rotate(45deg);
    }
    #line-bottom {
      transform: translate(1253.874px + 80px, 2417.685px);
    }
    #button-background {
      fill: transparent;
    }
  }
}
</style>
