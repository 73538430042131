<template>
  <footer class="footer">
    <div class="container">
      <div class="row top">
        <div class="footer-logo-text">
          <krootLogoNoBackground class="kroot-logo" />
          <p class="kroot-text mono-medium">&lt;Digitaal vakmanschap/&gt;</p>
        </div>
        <div class="row">
          <div class="navbar-links">
            <router-link class="navbar-link mono-medium" aria-label="ga naar Sitemap" :to="{name: 'Sitemap'}">Sitemap</router-link>
            <router-link class="navbar-link mono-medium" aria-label="ga naar Diensten" :to="{name: 'Diensten'}">Diensten</router-link>
            <BaseButton
              class="mailto-link navbar-link"
              inverse-ghost
              router-link
              :to="{ name: 'Contact' }"
              small
              aria-label="Neem contact met ons op"
            >Contact</BaseButton>
          </div>
        </div>
      </div>
      <hr />
      <div class="row bottom">
        <div class="social-media">
          <a class="social-link" href="https://www.facebook.com/Kroot-110704567068319" target="_blank">
            <img class="social-image" src="img/icons/facebook.svg" alt="Facebook" />
          </a>
          <a class="social-link" href="https://www.instagram.com/krootdevelopment" target="_blank">
            <img
              class="social-image"
              src="img/icons/instagram.svg"
              alt="Instagram"
            />
          </a>
          <a class="social-link" href="https://twitter.com/Kroot84079261" target="_blank">
            <img
              class="social-image"
              src="img/icons/twitter.svg"
              alt="Twitter"
            />
          </a>
        </div>
        <div class="company-data">
          <p class="mono-small">Oudenoord 330, 3513 EX Utrecht</p>
          <p class="mono-small">© Kroot. {{currentYear}}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import krootLogoNoBackground from '@/assets/images/krootLogoNoBackground.svg.vue';
import BaseButton from '@/components/global/BaseButton.vue';

export default {
  name: 'footerRow',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  components: {
    krootLogoNoBackground,
    BaseButton,
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: $screen-xlg-max - 320px;
}

.footer {
  padding: 80px 0;
  background: $black;
  color: $white;
}

hr {
  margin: 20px 0 30px 0;
  border: none;
  border-top: 1px solid $greyLight;
}

.footer-logo-text {
  display: flex;
  flex-flow: row;
  align-items: center;
  .kroot-text {
    margin-left: 40px;
    color: $greyMedium;
  }
  .kroot-logo {
    margin-left: 15px;
  }
}

.company-data {
  .mono-small {
    line-height: 30px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

svg {
  transform: rotate(90deg);
  width: 20px;
  height: 50px;
}

.navbar-links {
  display: flex;
  flex-flow: row;
  right: 0px;
  align-items: center;

  .navbar-link {
    text-align: left;
    color: $greyMedium;

    &:not(:first-child) {
      margin-left: 40px;
    }
  }
}

.company-data {
  width: 404px;
  display: flex;
  justify-content: space-between;
  color: $greyLight;
}

.social-image {
  margin-right: 15px;
  width: 30px;
}

@include lg {
  .navbar-links {
    .navbar-link {
      margin-left: 20px;
    }
  }
}

@include md {
  .footer-logo-text {
    align-items: flex-start;
    flex-flow: column;
    .kroot-text {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  .row {
    flex-flow: column;

    &.bottom {
      display: flex;

      .company-data {
        width: 100%;
      }
    }
  }

  .navbar-links {
    flex-flow: column;
    margin-left: 0px;
    align-items: flex-start;

    .navbar-link {
      margin-left: 0px;
      margin-bottom: 10px;

      &:not(:first-child) {
        margin-left: 0;
      }
    }

    .mailto-link {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .social-image {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  @include sm {
    .row.top {
      justify-content: center;
      align-items: center;
      text-align: center;

      .navbar-links,
      .footer-logo-text {
        display: flex;

        align-items: center;

        .kroot-logo {
          margin-left: 0;
        }
      }
    }
    .social-media {
      display: flex;
      justify-content: center;

      .social-link:last-of-type .social-image {
        margin-right: 0;
      }
    }

    .company-data {
      padding: 0 10px;
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
