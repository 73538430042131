<template>
  <div id="app" :class="{ collapsed: sideMenuVisible }" >
    <DebugInfo v-if="isDevelopment" />
    <Navbar
      @hamburger-clicked="toggleSideMenu"
      :sideMenuVisible.sync="sideMenuVisible"
      :scrolledToTop="scrolledToTop"
    />
    <div class="side-menu">
      <nav class="side-menu-links" aria-hidden="true">
        <router-link
          @click.native="closeSideMenu"
          :class="{ hidden: !sideMenuVisible }"
          class="side-menu-link"
          :to="{name: 'Home'}"
        >Home</router-link>
        <router-link
          @click.native="closeSideMenu"
          :class="{ hidden: !sideMenuVisible }"
          class="side-menu-link"
          :to="{name: 'Diensten'}"
        >Diensten</router-link>
        <router-link
          @click.native="closeSideMenu"
          :class="{ hidden: !sideMenuVisible }"
          class="side-menu-link"
          :to="{name: 'Contact'}"
        >Contact</router-link>
      </nav>
    </div>
    <div id="content-wrapper" class="content-wrapper" ref="content" @click="closeSideMenu">
      <div class="page">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>


<script>
import { DebugInfo, Navbar, Footer } from '@/components/global';

export default {
  name: 'App',
  components: { Navbar, DebugInfo, Footer },
  data() {
    return {
      sideMenuVisible: false,
      contentRef: null,
      scrolledToTop: true,
    };
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    toggleSideMenu() {
      this.sideMenuVisible = !this.sideMenuVisible;
    },
    closeSideMenu() {
      this.sideMenuVisible = false;
    },
    handleScroll() {
      this.scrolledToTop = this.$refs.content.scrollTop < 5;
    },
  },
  mounted() {
    this.contentRef = this.$refs.content;
    if (!this.contentRef) {
      throw new Error(
        "No reference to content found. Scroll listener(s) won't work.",
      );
    } else {
      this.contentRef.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if (this.contentRef) {
      this.contentRef.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>


<style lang="scss" scoped>
.content {
  padding: 80px;
}

#app {
  z-index: 1;

  .side-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: $darkBlueToRed;

    z-index: 0;
  }

  .side-menu-links {
    visibility: hidden; // Hide from screen-readers
    padding-right: 30px;

    .side-menu-link {
      @extend .h3;

      display: block;
      color: white;
      text-decoration: none;
      text-align: right;

      opacity: 1;
      transition: 0.3s all ease-in-out;
      transform: translate(0, 0);
      @include staggered-transitions($items: 10, $initial: 0.15, $step: 0.1);

      margin: 20px 0;

      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

      &.hidden {
        transform: translate(100%, 0) translateX(30px);
        opacity: 0;
      }
    }
  }

  .content-wrapper {
    background-color: #fff;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
    @include md {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    z-index: 0;

    transition: 0.5s all ease-in-out;
    border-radius: 1px;
  }

  &.collapsed {
    .side-menu-links {
      visibility: visible; // Show to screen-readers
    }

    .content-wrapper {
      transform: scale(0.75) translate(-300px, 40px);
      overflow: hidden;
      box-shadow: 0 15px 30px rgba(black, 0.15);
      border-radius: 20px;
      cursor: pointer;

      .page {
        pointer-events: none;
      }
    }
  }
}
</style>
