<template>
  <a alt="Kroot logo" aria-label="Ga naar hoofdpagina" v-on:click="scrollOrHome()" class="logo">
    <krootLogoImage :sideMenuVisible="sideMenuVisible"></krootLogoImage>
    <p class="logo-text" :class="{'not-visible': hideText, 'white-text': sideMenuVisible}">Kroot.</p>
  </a>
</template>

<script>
import krootLogoImage from '@/assets/images/krootLogo.svg.vue';

export default {
  name: 'Logo',
  components: {
    krootLogoImage,
  },
  props: {
    hideText: Boolean,
    sideMenuVisible: {
      type: Boolean,
    },
  },
  methods: {
    scrollOrHome() {
      if (this.$router.currentRoute.name === 'Home') {
        document.getElementById('content-wrapper').scrollTop = 0;
        return;
      }

      this.$router.push({ name: 'Home' });
    },
  },
};
</script>


<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  .logo-text {
    font-family: 'Avenir Next', sans-serif;
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 20px;
    transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out,
      margin-left 0.5s ease-in-out;
    color: $black;

    &.not-visible {
      opacity: 0;
    }

    &.white-text {
      color: white;
      margin-left: 0;
      transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out,
        margin-left 0.5s ease-in-out 0.5s;
    }
  }
}
</style>
