<template>
  <div class="cta-row page-row">
    <div class="container">
      <div class="content-text">
        <p class="mono-small">&lt;Callto: Action/&gt;</p>
        <h2 class="h2">Optimaliseren voor een digitaal landschap?</h2>
        <BaseButton
          gradient
          link="mailto:company@kroot.dev"
          aria-label="Mail ons"
        >Mailto:</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@/components/global';

export default {
  name: 'CallToActionRow',
  components: {
    BaseButton,
  },
  props: {},
};
</script>

<style lang="scss" scoped>
.cta-row {
  .container {
    max-width: 630px;
    .content-text {
      text-align: center;
      padding: 0 10px;
    }
  }
}
</style>
