<template>
  <div>
    <HeroRow />
    <main>
      <!-- <ProjectsPreviewRow /> -->
      <OurTeamRow />
      <OurServicesRow/>
      <ClientShowcaseRow />
      <CallToActionRow />
    </main>
  </div>
</template>

<script>
import {
  // ProjectsPreviewRow,
  HeroRow,
  OurTeamRow,
  OurServicesRow,
  ClientShowcaseRow,
} from '@/components/rows/home';

import CallToActionRow from '@/components/rows/CallToActionRow.vue';


export default {
  name: 'home',
  components: {
    // ProjectsPreviewRow,
    ClientShowcaseRow,
    HeroRow,
    OurTeamRow,
    OurServicesRow,
    CallToActionRow,
  },
  head: {
    title: {
      inner: 'Home',
    },
    meta: [
      { name: 'description', content: 'Kroot. Homepage', id: 'desc' },
      { property: 'og:description', content: 'Kroot. Digitaal Vakmanschap' },
      { property: 'og:title', content: 'Kroot. Digitaal Vakmanschap' },
      { property: 'og:url', content: 'https://kroot.dev/' },
      { name: 'twitter:card', content: 'Kroot. Digitaal Vakmanschap' },
    ],
    link: [
      { rel: 'canonical', href: 'https://kroot.dev' },
    ],
  },
};
</script>
<style scoped lang="scss">
.hero-page {
  position: relative;
  z-index: 1;

  .welcome {
    text-align: left;
    font-family: "PT Mono", monospace;
    font-size: 21px;
    letter-spacing: 0;
    color: #0f0b33;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .headline {
    text-align: left;
    font-family: "Avenir Next", sans-serif;
    font-size: 64px;
    letter-spacing: 0.9px;
    color: #0f0b33;
    line-height: 80px;
    margin-bottom: 45px;
  }

  /deep/ .subline > button {
    font-family: "PT Mono", monospace;
    font-size: 16px;
  }

  .dots {
    display: block;
    width: 100%;
    height: 500px;
  }
}

.hero-page-images {
  #claw {
    width: 1655px;
    height: 1655px;
  }

  #KrootPhone {
    margin-top: -900px;
    margin-left: 175px;
    width: 926px;
    height: auto;
  }
}
</style>
