<template>
  <div ref="canvas-container" class="canvas-container">
    <canvas v-if="animated" ref="canvas-prerender" width="2" height="2" class="canvas-prerender"></canvas>
    <div v-if="!animated" class="solid-dots" :class="colorType"></div>
  </div>
</template>

<script>
export default {
  name: 'CanvasDots',
  props: {
    step: {
      type: Number,
      default: 20,
    },
    circleColour: {
      type: String,
      default: '#C2C1CB',
    },
    bgColour: {
      type: String,
      default: 'black',
    },
    animated: {
      type: Boolean,
      default: false,
    },
    colorType: {
      type: String,
      validator(val) {
        return ['dark', 'light'].includes(val);
      },
      default: 'dark',
    },
  },
  data() {
    return {
      canvasWrapper: null,
      canvas: null,
      canvasContext: null,
      canvasWidth: null,
      canvasHeight: null,
      prerenderCanvas: null,
      prerenderCanvasContext: null,
      mouseX: -1,
      mouseY: -1,
      currentPositions: [],
      shouldDraw: false,
      skipShouldDrawCheck: true,
      timeoutThatSetsShouldDrawToFalseHandle: null,
      animationFrame: null,
    };
  },
  mounted() {
    if (!this.animated) {
      return;
    }

    this.initialize();
  },
  destroyed() {
    if (!this.animated) {
      return;
    }

    this.breakdown();
  },
  methods: {
    initialize() {
      this.canvasWrapper = this.$refs['canvas-container'];
      this.canvasWidth = this.canvasWrapper.clientWidth;
      this.canvasHeight = this.canvasWrapper.clientHeight;

      this.canvas = document.createElement('canvas');
      this.canvasContext = this.canvas.getContext('2d');
      this.canvas.width = this.canvasWidth;
      this.canvas.height = this.canvasHeight;
      this.canvas.addEventListener('mousemove', this.handleMouseMove);
      this.canvas.addEventListener('mouseenter', this.handleMouseEnter);
      this.canvas.addEventListener('mouseleave', this.handleMouseLeave);
      this.canvasWrapper.appendChild(this.canvas);

      this.prerenderCanvas = this.$refs['canvas-prerender'];
      this.prerenderCanvasContext = this.prerenderCanvas.getContext('2d');
      this.prerenderCanvasContext.fillStyle = this.circleColour;
      this.prerenderCanvasContext.beginPath();
      this.prerenderCanvasContext.arc(1, 1, 1, 0, Math.PI * 2, false);
      this.prerenderCanvasContext.closePath();
      this.prerenderCanvasContext.fill();

      this.animationFrame = window.requestAnimationFrame(this.draw);
      this.handleMouseLeave();
      window.addEventListener('resize', this.handleResize);
    },
    breakdown() {
      window.removeEventListener('resize', this.handleResize);
      this.canvas.removeEventListener('mousemove', this.handleMouseMove);
      this.canvas.removeEventListener('mouseenter', this.handleMouseEnter);
      this.canvas.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    handleResize() {
      // Re-fetch the width / height
      this.canvasWidth = this.canvasWrapper.clientWidth;
      this.canvasHeight = this.canvasWrapper.clientHeight;
      this.canvas.width = this.canvasWidth;
      this.canvas.height = this.canvasHeight;

      // Cancel the animation loop, and restart it
      window.cancelAnimationFrame(this.animationFrame);
      this.skipShouldDrawCheck = true;
      this.animationFrame = window.requestAnimationFrame(this.draw);
    },
    draw() {
      if (!this.shouldDraw && !this.skipShouldDrawCheck) {
        return;
      }
      this.skipShouldDrawCheck = false;

      if (this.bgColour === 'transparent') {
        this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      } else {
        this.canvasContext.fillStyle = this.bgColour;
        this.canvasContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
      }

      // Draw circles with 20px in between, starting from top-left (0, 0) with color #C2C1CB.
      // We use a 2px offset because the circles are drawn with center as their origin.
      let i = 0;
      for (let x = 2; x < this.canvasWidth; x += this.step) {
        for (let y = 2; y < this.canvasHeight; y += this.step) {
          this.drawCircle(x, y, this.circleColour, i);
          i += 1;
        }
      }

      this.animationFrame = window.requestAnimationFrame(this.draw);
    },
    drawCircle(targetX, targetY, colorHex = '#fff', index) {
      const xDistFromMouse = targetX - this.mouseX;
      const yDistFromMouse = targetY - this.mouseY;
      const distanceFromMouse = Math.sqrt(
        xDistFromMouse * xDistFromMouse + yDistFromMouse * yDistFromMouse,
      ) || 1;

      let xWithOffset = targetX;
      let yWithOffset = targetY;

      if (!this.currentPositions[index]) {
        this.currentPositions[index] = { x: targetX + 1000, y: targetY + 1000 };
      }

      if (this.mouseX >= 0 && this.mouseY >= 0 && colorHex) {
        const xOffset = (xDistFromMouse / distanceFromMouse * 1.2) * 30;
        const yOffset = (yDistFromMouse / distanceFromMouse * 1.2) * 30;
        xWithOffset += xOffset;
        yWithOffset += yOffset;
      }

      const currentPosition = this.currentPositions[index];
      const newCurrentPositionX = currentPosition.x
        + (1 - currentPosition.x / (xWithOffset + 1000)) * 300;
      const newCurrentPositionY = currentPosition.y
        + (1 - currentPosition.y / (yWithOffset + 1000)) * 300;
      this.currentPositions[index] = {
        x: newCurrentPositionX,
        y: newCurrentPositionY,
      };

      this.canvasContext.drawImage(
        this.prerenderCanvas,
        newCurrentPositionX - 1000,
        newCurrentPositionY - 1000,
      );
    },
    /**
     * This function will be called every time the mouse moves over the canvas element.
     * The purpose of this function is to calculate the mouse x and y coordinates, relative
     * to the canvas origin point (top-left), and save this to the mouseX/mouseY variables.
     *
     * This function draws inspiration (i.e. code) from:
     * https://stackoverflow.com/questions/17130395/real-mouse-position-in-canvas
     *
     * @param e Event the event passed when callback is fired from the EventListener
     * */
    handleMouseMove(e) {
      const canvasRect = this.canvas.getBoundingClientRect();
      this.mouseX = ((e.clientX - canvasRect.left) / (canvasRect.right - canvasRect.left))
        * this.canvas.width;
      this.mouseY = ((e.clientY - canvasRect.top) / (canvasRect.bottom - canvasRect.top))
        * this.canvas.height;
    },
    handleMouseLeave() {
      this.mouseX = -1;
      this.mouseY = -1;
      this.timeoutThatSetsShouldDrawToFalseHandle = setTimeout(() => {
        this.shouldDraw = false;
        window.cancelAnimationFrame(this.animationFrame);
      }, 500);
    },
    handleMouseEnter() {
      this.shouldDraw = true;
      window.cancelAnimationFrame(this.animationFrame);
      this.animationFrame = window.requestAnimationFrame(this.draw);

      // Clear handle to prevent soft-lock
      if (this.timeoutThatSetsShouldDrawToFalseHandle) {
        clearTimeout(this.timeoutThatSetsShouldDrawToFalseHandle);
        this.timeoutThatSetsShouldDrawToFalseHandle = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-prerender {
  display: none;
}

.canvas-container {
  height: inherit;
  overflow: hidden;
}

.solid-dots {
  height: inherit;
  background-position:  2px 10px;

  &.dark {
    background-image: url('../../assets/images/dot-black.png');
    opacity: 0.2;
  }

  &.light {
    background-image: url('../../assets/images/dot-white.png');
  }
}
</style>
