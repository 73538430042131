<template>
  <div class="debug-info">
    <h4>Debug info</h4>
    <p>Breakpoints active: <span class="xsm">xsm, </span><span class="sm">sm, </span><span class="md">md, </span><span class="lg">lg, </span><span class="xlg">xlg, </span><span>all</span></p>
  </div>
</template>

<script>
export default {
  name: 'DebugInfo',
  props: {},

  head: {
    // prevent search engines from indexing the page (when in production).
    // See https://developers.google.com/search/reference/robots_meta_tag
    meta: [
      { name: 'robots', content: 'noindex' },
    ],
  },
};
</script>

<style lang="scss" scoped>
.debug-info {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 180px;
  background-color: black;
  z-index: 9999;
  color: green;
  font-family: monospace;
  padding: 20px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  box-shadow: 0 0  20px rgba(black, 0.3);

  h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .xsm, .sm, .md, .lg, .xlg {
    display: none;
  }

  @include xsm {
    .xsm {
      display: inline;
    }
  }

  @include sm {
    .sm {
      display: inline;
    }
  }

  @include md {
    .md {
      display: inline;
    }
  }

  @include lg {
    .lg {
      display: inline;
    }
  }

  @include xlg {
    .xlg {
      display: inline;
    }
  }
}
</style>
