import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueHead from 'vue-head';
import VueLazyload from 'vue-lazyload';
import VueObserveVisibility from 'vue-observe-visibility';
import VueBrowserUpdate from 'vue-browserupdate';
import App from './App.vue';
import router from './router';

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID,
  router,
  debug: {
    // Don't send events when developing.
    sendHitTask: process.env.NODE_ENV === 'production',
  },
});
Vue.use(VueHead, {
  complement: 'Kroot. Digitaal Vakmanschap',
});
Vue.use(VueLazyload);
Vue.use(VueObserveVisibility);

Vue.use(VueBrowserUpdate, {
  options: {
    container: document.body,
    style: 'bottom',
    test: false, // set to true for testing
    text: 'Je browser, {brow_name}, is verouderd. Werk deze bij voor de beste ervaring op onze website. <a{up_but}>Bijwerken</a> of <a{ignore_but}>Negeren</a>',
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
