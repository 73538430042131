import BaseButton from './BaseButton.vue';
import BaseImage from './BaseImage.vue';
import MaskEffectImage from './MaskEffectImage.vue';
import CanvasDots from './CanvasDots.vue';
import DebugInfo from './DebugInfo.vue';
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';

export {
  BaseButton,
  BaseImage,
  MaskEffectImage,
  CanvasDots,
  DebugInfo,
  Footer,
  Navbar,
};
